import {
  faBell,
  faBroadcastTower,
  faChartLine,
  faClipboardList,
  faCloudMoon,
  faCloudSun,
  faCommentsDollar,
  faDollar,
  faLaptopCode,
  faTrophy,
  faUser,
  faUserFriends,
  faUsersCog,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { useAuthorize } from '../../auth/authorize';
import Role from '../../auth/roles';
import { GridContainer } from '../../components/dumb/containers';
import { PageHeader, SectionHeader } from '../../components/dumb/typography';
import { useImpersonationContext } from '../../context/impersonation-context';
import { useUser } from '../../context/user-context';

const Home: React.FC = () => {
  const authorize = useAuthorize();
  const user = useUser();
  const { impersonating, currentUser } = useImpersonationContext();

  return (
    <>
      <PageHeader />
      <GridContainer>
        <SectionHeader className="grid-6">
          Welcome to your Novus apps!
        </SectionHeader>
        <HomeLink to={`/profile/${impersonating ? currentUser.userId : ''}`}>
          <HomeSvg icon={faUser} size="2x" />
          My Profile
        </HomeLink>
        <HomeLink to={`/leaderboard`}>
          <HomeSvg icon={faTrophy} size="2x" />
          Leaderboards
        </HomeLink>
        <HomeLink to="/notifications">
          <HomeSvg icon={faBell} size="2x" />
          Notification Preferences
        </HomeLink>

        {authorize({ allowedRoles: [Role.PaymentsAdmin] }) && (
          <HomeLink to="/payment-utilities">
            <HomeSvg icon={faDollar} size="2x" />
            Payment Utilities
          </HomeLink>
        )}

        {authorize({
          allowedRoles: [Role.ProfileAdmin],
        }) && (
          <HomeLink to="/profile-admin">
            <HomeSvg icon={faUsersCog} size="2x" />
            Profile Administration
          </HomeLink>
        )}

        {/* Temporarily disabled as of August 2024 */}
        {/* {authorize({
          allowedRoles: [Role.SmartLeadsUser],
        }) && (
          <HomeLink to="/smartleads">
            <HomeSvg icon={faChartLine} size="2x" />
            SmartLeads
          </HomeLink>
        )} */}

        {authorize({
          allowedRoles: [Role.BranchSupport],
        }) && (
          <HomeLink to="forecasts">
            <HomeSvg icon={faCloudSun} size="2x" />
            Company Forecasts
          </HomeLink>
        )}

        {authorize({
          allowedRoles: [Role.BranchManager, Role.BranchSupport],
        }) &&
          (user?.branchManagerBranchCodes?.length === 1 &&
          !user?.roles.includes(Role.BranchSupport) ? (
            <>
              <HomeLink
                to={`forecasts/branches/history/${user?.branchManagerBranchCodes[0]}`}
              >
                <HomeSvg icon={faCloudMoon} size="2x" />
                My Forecast History
              </HomeLink>
              <HomeLink
                to={`forecasts/branches/current/${user?.branchManagerBranchCodes[0]}`}
              >
                <HomeSvg icon={faCloudSun} size="2x" />
                My Current Forecast
              </HomeLink>
            </>
          ) : (
            <>
              <HomeLink to="forecasts/branches/history">
                <HomeSvg icon={faCloudMoon} size="2x" />
                {user?.roles.includes(Role.BranchManager) &&
                !user?.roles.includes(Role.BranchSupport)
                  ? 'My '
                  : ''}
                Forecast History
              </HomeLink>
              <HomeLink to="forecasts/branches/current">
                <HomeSvg icon={faCloudSun} size="2x" />
                {user?.roles.includes(Role.BranchManager) &&
                !user?.roles.includes(Role.BranchSupport)
                  ? 'My '
                  : ''}
                Current Forecasts
              </HomeLink>
            </>
          ))}
        {authorize({
          allowedRoles: [
            Role.OnboardingAdmin,
            Role.OnboardingManager,
            Role.OnboardingApprover,
            Role.OnboardingHiringManager,
          ],
        }) && (
          <HomeLink to="/onboarding">
            <HomeSvg icon={faClipboardList} size="2x" />
            Onboarding Management
          </HomeLink>
        )}

        {authorize({}) && (
          <HomeLink to="/broadcasts">
            <HomeSvg icon={faBroadcastTower} size="2x" />
            Emergency Broadcasts
          </HomeLink>
        )}
        {authorize({
          allowedRoles: [
            Role.BranchSupport,
            Role.SmartLeadsAdmin,
            Role.ProfileAdmin,
          ],
        }) && (
          <HomeLink to="/user-list">
            <HomeSvg icon={faUserFriends} size="2x" />
            User List
          </HomeLink>
        )}
        {authorize({}) && (
          <HomeLink to="/admin">
            <HomeSvg icon={faLaptopCode} size="2x" />
            Admin
          </HomeLink>
        )}
        {authorize({
          allowedRoles: [
            Role.Administrator,
            Role.BranchManager,
            Role.SalesManager
          ]
        }) && (
          <HomeLinkAlt
            href="https://app.powerbi.com/groups/me/apps/283a8de0-6a95-4bd9-93ee-8603aad364f9/reports/b4b53eb5-ba9a-4f01-a2ad-932a0b56cdc1/ReportSection?experience=power-bi"
            target="_blank"
          >
            <HomeSvg icon={faLaptopCode} size="2x" />
            BDA-Admin
          </HomeLinkAlt>
        )}
        {authorize({
          allowedRoles: [
            Role.Administrator,
            Role.BranchManager
          ]
        }) && (
          <HomeLinkAlt
            href="https://loanofficer-prod-dqd6gyc8gwaugreh.a02.azurefd.net/"
            target="_blank"
          >
            <HomeSvg icon={faChartLine} size="2x" />
            LowerMyRate Portal
          </HomeLinkAlt>
        )}
      </GridContainer>
    </>
  );
};

export default Home;

const HomeLink = styled(Link)`
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  font-weight: 600;
  padding: 4rem 1rem;
  border: 2px solid ${(p) => p.theme.bgColors.primary};
  color: ${(p) => p.theme.bgColors.primary};
  position: relative;
  display: block;
  &:hover,
  &:focus {
    background: ${(p) => p.theme.bgColors.primary};
    color: white;
    outline: none;
  }
`;

const HomeLinkAlt = styled.a`
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  font-weight: 600;
  padding: 4rem 1rem;
  border: 2px solid ${(p) => p.theme.bgColors.primary};
  color: ${(p) => p.theme.bgColors.primary};
  position: relative;
  display: block;
  &:hover,
  &:focus {
    background: ${(p) => p.theme.bgColors.primary};
    color: white;
    outline: none;
  }
`;

const HomeSvg = styled(FontAwesomeIcon)`
  &.svg-inline--fa {
    margin: 0 auto;
  }
  display: block;
`;
